import { createRouter, createWebHistory } from 'vue-router';
import App from './pages/app/App.vue';

const routes = [
    {
        path: '',
        component: () => import('./pages/auth/Layout.vue'),
        children: [
            {
                path: '',
                name: 'login',
                meta: { auth: false },
                component: () => import('./pages/auth/Login.vue'),
            },
        ],
    },
    {
        component: App,
        meta: { auth: true },
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('./pages/dashboard/Dashboard.vue')
            },
            {
                path: '/user',
                name: 'user-list',
                props: true,
                component: () => import('./pages/users/Users.vue'),
            },
            {
                path: '/user/:id',
                name: 'user-detail',
                props: true,
                component: () => import('./pages/users/Detail.vue'),
            },
            {
                path: '/customer',
                component: () => import('./pages/customer/Customer.vue'),
                children: [
                    { path: '', redirect: {name: 'customer-list'} },
                    {
                        path: 'list',
                        name: 'customer-list',
                        component: () => import('./pages/customer/ListCustomer.vue'),
                    },
                    {
                        path: 'request-upgrade',
                        name: 'customer-list-upgrade',
                        component: () => import('./pages/customer/ListRequest.vue'),
                    },
                ],
            },
            {
                path: '/customer/detail/:id',
                name: 'customer-list-detail',
                props: true,
                component: () => import('./pages/customer/Detail.vue'),
            },
            {
                path: '/customer/request-upgrade/:id',
                name: 'customer-list-upgrade-detail',
                props: true,
                component: () => import('./pages/customer/Detail.vue'),
            },
            {
                path: '/transaction',
                name: 'transactions',
                props: true,
                component: () => import('./pages/transactions/Transactions.vue'),
            },
            {
                path: '/transaction/:id',
                name: 'transaction-detail',
                props: true,
                component: () => import('./pages/transactions/Detail.vue'),
            },
            {
                path: '/content-management',
                name: 'content-management',
                component: () => import('./pages/content/Index.vue'),
            },
            {
                path: '/content-management/add',
                name: 'add-content',
                component: () => import('./pages/content/Form.vue'),
            },
            {
                path: '/content-management/update/:id',
                name: 'edit-content',
                component: () => import('./pages/content/Form.vue'),
            },
            {
                path: '/settings',
                component: () => import('./pages/settings/Index.vue'),
                children: [
                    { path: '', redirect: {name: 'settings-source-of-fund'} },
                    {
                        path: 'source-of-fund',
                        name: 'settings-source-of-fund',
                        component: () => import('./pages/settings/source-of-fund/SOF.vue'),
                    },
                    {
                        path: 'holding-account',
                        name: 'settings-holding-account',
                        component: () => import('./pages/settings/holding-account/HoldingAccount.vue'),
                    },
                    {
                        path: 'transfer-fee',
                        name: 'settings-transfer-fee',
                        component: () => import('./pages/settings/transfer-fee/TransferFee.vue'),
                    },
                    {
                        path: 'menu',
                        name: 'settings-menu',
                        component: () => import('./pages/settings/menu/Menu.vue'),
                    },
                    {
                        path: 'service',
                        name: 'settings-service',
                        component: () => import('./pages/settings/service/Service.vue'),
                    },
                    {
                        path: 'mynt-binding',
                        name: 'settings-mynt-binding',
                        component: () => import('./pages/settings/mynt-binding/MyntBinding.vue'),
                    },
                ],
            },
            {
                path: '/master-data/product',
                name: 'product',
                component: () => import('./pages/master-data/product/Product.vue'),
            },
            {
                path: '/master-data/denom',
                name: 'denom',
                component: () => import('./pages/master-data/denom/Denom.vue'),
            },
            {
                path: '/master-data/prefix',
                name: 'prefix',
                component: () => import('./pages/master-data/prefix/Prefix.vue'),
            },
            {
                path: '/master-data/bank',
                name: 'bank',
                component: () => import('./pages/master-data/bank/Bank.vue'),
            },
            {
                path: '/data-assets/country',
                name: 'country',
                component: () => import('./pages/data-assets/country/Country.vue'),
            },
            {
                path: '/data-assets/province',
                name: 'province',
                component: () => import('./pages/data-assets/province/Province.vue'),
            },
            {
                path: '/data-assets/city',
                name: 'city',
                component: () => import('./pages/data-assets/city/City.vue'),
            },
            {
                path: '/data-assets/district',
                name: 'district',
                component: () => import('./pages/data-assets/district/District.vue'),
            },
            {
                path: '/formlayout',
                name: 'formlayout',
                component: () => import('./components/demo/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                name: 'input',
                component: () => import('./components/demo/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                name: 'floatlabel',
                component: () => import('./components/demo/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                name: 'invalidstate',
                component: () => import('./components/demo/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                name: 'button',
                component: () => import('./components/demo/ButtonDemo.vue')
            },
            {
                path: '/table',
                name: 'table',
                component: () => import('./components/demo/TableDemo.vue')
            },
            {
                path: '/list',
                name: 'list',
                component: () => import('./components/demo/ListDemo.vue')
            },
            {
                path: '/tree',
                name: 'tree',
                component: () => import('./components/demo/TreeDemo.vue')
            },
            {
                path: '/panel',
                name: 'panel',
                component: () => import('./components/demo/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                name: 'overlay',
                component: () => import('./components/demo/OverlayDemo.vue')
            },
            {
                path: '/media',
                name: 'media',
                component: () => import('./components/demo/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/demo/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/demo/MessagesDemo.vue')
            },
            {
                path: '/file',
                name: 'file',
                component: () => import('./components/demo/FileDemo.vue')
            },
            {
                path: '/chart',
                name: 'chart',
                component: () => import('./components/demo/ChartDemo.vue')
            },
            {
                path: '/misc',
                name: 'misc',
                component: () => import('./components/demo/MiscDemo.vue')
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue')
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/demo/EmptyPage.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/demo/Documentation.vue')
            },
            {
                path: '/blocks',
                name: 'blocks',
                component: () => import('./components/demo/BlocksDemo.vue')
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('./components/demo/IconsDemo.vue')
            }
        ]
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/:pathMatch(.*)',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
      },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
