<template>
	<ul v-if="items">
		<template v-for="(item, i) of items">
			<li v-if="visible(item) && !item.separator" :key="item.label || i"
				:class="[{
					'layout-menuitem-category mb-5': root,
					'active-menuitem': activeIndex === i && !item.to && !item.disabled
				}]" 
				role="none">

				<template v-if="root">
					<div class="layout-menuitem-root-text mb-3" :aria-label="item.label">
						{{ item.label }}
					</div>
					<appsubmenu :items="visible(item) && item.items"
						@menuitem-click="$emit('menuitem-click', $event)">
					</appsubmenu>
				</template>

				<template v-else>
					<router-link v-if="item.to" :to="item.to"
						:class="[item.class, 'p-ripple', {'p-disabled': item.disabled, 'router-link-active router-link-exact-active': path.includes(item.to)}]"
						:style="item.style" @click="onMenuItemClick($event,item,i)"
						:target="item.target" :aria-label="item.label" role="menuitem" v-ripple
					>
						<template v-if="path.includes(item.to)">
							<img :src="require(`@/assets/icons/sidebar/${item.icon}-active.svg`)" alt="" v-if="item.icon" />
						</template>
						<template v-else>
							<img :src="require(`@/assets/icons/sidebar/${item.icon}.svg`)" alt="" v-if="item.icon" />
						</template>
						
						<span class="ml-3">
							{{ item.label }}
						</span>
						<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
						<Badge v-if="item.badge" :value="item.badge"></Badge>
					</router-link>

					<a v-if="!item.to" :href="item.url||'#'"
						:style="item.style" 
						:class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]"
						@click="onMenuItemClick($event,item,i)" 
						:target="item.target" :aria-label="item.label" role="menuitem" v-ripple
					>
						<template v-if="path.includes(item.to)">
							<img :src="require(`@/assets/icons/sidebar/${item.icon}-active.svg`)" alt="" v-if="item.icon" />
						</template>
						<template v-else>
							<img :src="require(`@/assets/icons/sidebar/${item.icon}.svg`)" alt="" v-if="item.icon" />
						</template>
						<span class="ml-3">
							{{ item.label }}
						</span>
						<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
						<Badge v-if="item.badge" :value="item.badge"></Badge>
					</a>

					<transition name="layout-submenu-wrapper">
						<appsubmenu v-show="activeIndex === i"
							:items="visible(item) && item.items"
							@menuitem-click="$emit('menuitem-click', $event)">
						</appsubmenu>
					</transition>
				</template>
			</li>

			<li class="p-menu-separator"
				:style="item.style"
				v-if="visible(item) && item.separator" :key="'separator' + i"
				role="separator">
			</li>
		</template>
	</ul>
</template>
<script>
export default {
	name: 'appsubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		'$route': 'fetchLink',
	},
	data() {
		return {
			activeIndex : null,
			path: '',
		}
	},
	mounted () {
		this.fetchLink();
	},
	methods: {
		onMenuItemClick(event, item, index) {
			if (item.disabled) {
				event.preventDefault();
				return;
            }

            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
			}

			this.activeIndex = index === this.activeIndex ? null : index;

			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		},
		visible(item) {
			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		},
		fetchLink() {
			this.path = this.$route.path
		},
	}
}
</script>
