<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo pl-3">
			<img src="~@/assets/images/logos/bersamaku.png" alt="" class="" />
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<img src="~@/assets/icons/menu-bars.svg" alt="">
		</button>
		<h4 class="mb-0 mt-0 ml-3 text-black opacity-60 roboto-light">Dashboard</h4>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<!-- <li>
				<button class="p-link layout-topbar-button">
					<img src="~@/assets/icons/bell.svg" alt="" />
					<Badge :value="3" severity="danger"
						class="flex align-items-center justify-content-center absolute bg-linear-red">
					</Badge>
				</button>
			</li> -->
			<li class="flex align-items-center justify-content-center ml-4">
				<div class="profile flex align-items-center justify-content-center border-circle bg-gray">
					<i class="pi pi-user"></i>
				</div>
				<h6 class="text-14 text-black opacity-60 my-0 ml-3">
					Hallo, <span class="roboto-medium">
						{{ user?.fullname }}
					</span>
				</h6>
			</li>
			<li>
				<Menu ref="menu" :model="overlayMenuProfiles" :popup="true" class="profile-dropdown" />
				<button class="p-link layout-topbar-button ml-2" id="myBtn" ref="button-profile" @click="toggleProfile">
					<img src="~@/assets/icons/chevron-down.svg" alt="" width="18" class="opacity-60">
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			overlayMenuProfiles: [
				// {
				// 	label: 'Save',
				// 	icon: 'pi pi-save',
				// 	command: () => {
				// 		this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
				// 	}
				// },
				// {
				// 	label: 'Update',
				// 	icon: 'pi pi-refresh'
				// },
				// {
				// 	label: 'Delete',
				// 	icon: 'pi pi-trash'
				// },
				// {
				// 	separator: true
				// },
				{
					label: 'Logout',
					icon: 'pi pi-sign-out',
					command: () => {
						this.$auth.logout({
							makeRequest: true,
							redirect: { name: 'login' },
						});
					}
				},
			],
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		user() {
			const user = this.$auth.user()
			return user;
		},
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		toggleProfile(event) {
			this.$refs.menu.toggle(event)
		},
    },
}
</script>

<style lang="scss" scoped>
.layout-topbar .layout-topbar-logo img{
	height: 3.5rem;
}
::v-deep(.p-badge) {
	top: 3px;
	right: 5px;
}
.profile {
	width: 35px;
	height: 35px;
}
</style>